<template>
	<div guide="orders-kanban-card" class="card border-5 border-start" :class="typeInfo.border" :draggable="draggable" @click="select" @dragstart="dragstart" @dragend="dragend" :style="{ 'opacity': (dragging) ? '20%' : '100%', 'border-style': (dragging) ? 'dashed' : 'solid' }">
		<div class="card-body p-4">
			<div class="d-flex justify-content-between align-items-center">
				<div class="d-block h3 text-dark fw-bolder">{{ order.number }}</div>
				<div class="spinner-border spinner-border-sm text-dark me-1" role="status" v-if="order.synchronizing"><span class="visually-hidden">Loading...</span></div>
				<div class="d-block h6 fw-bold" :class="timerClass" v-else>{{ timerText }}</div>
			</div>
			<div class="hstack gap-3 justify-content-between mt-2">
				<div class="d-block text-sm text-muted text-truncate">{{ title }}</div>
				<div class="hstack gap-1">
					<span class="badge rounded-pill border me-1" :class="{'bg-secondary bg-opacity-10 text-dark': ((order?.payments.length ?? 0) > 0), 'border-danger text-danger': ((order?.payments.length ?? 0) == 0)}" v-if="!order.order_group_id">{{ paymentsInfo }}</span>
					<span class="badge rounded-pill border" :class="typeInfo.badge">{{ typeTitle }}</span>
				</div>
			</div>
			<orders-kanban-card-shipping :status="order.status" :rider="shipping?.rider" :shippingInfo="shippingInfo" v-if="hasShipping" />
		</div>
	</div>
</template>

<script>
	import { ref, computed, watch, onMounted, onUnmounted } from 'vue';
	import moment from 'moment';
	import composableOrder from '@/composables/order';

	export default {
		emits: ['setdrag', 'select'],
		props: {
			order: Object
		},
		setup(props, { emit }) {
			const { reload, title, paymentsInfo, typeInfo, typeTitle, shippingInfo, hasShipping, shipping } = composableOrder(props.order);
			const interval = ref(null);
			const diff_created = ref(0);
			const diff_ready = ref(0);
			const dragging = ref(false);
			const draggable = computed(() => (!props.order.synchronizing && window.innerWidth >= 768));

			const updateDiff = () => {
				diff_created.value = moment().diff(props.order.created_at) * -1;

				if(!props.order.ready_by) diff_ready.value = 0;
				else diff_ready.value = moment().diff(props.order.ready_by) * -1;
			}

			const timerClass = computed(() => {
				if(props.order.is_scheduled && props.order.status == 'PENDING') return 'text-info';
				else if(props.order.status == 'PENDING') return 'text-dark';
				else if(diff_ready.value > 0) return 'text-success';
				else return 'text-danger';
			});

			const timerText = computed(() => {
				if(props.order.is_scheduled && props.order.status == 'PENDING') return moment(props.order.ready_by).format('[Para el] dddd D [a las] HH:mm');
				else if(props.order.status == 'PENDING') return `Creado hace ${moment.duration(diff_created.value).locale('es').humanize(false)}`;
				else return `${(diff_ready.value < 0) ? 'Atrasado' : 'Terminar en'} ${moment.duration(diff_ready.value).locale('es').humanize(false)}`;
			});

			onMounted(() => {
				updateDiff();
				interval.value = setInterval(updateDiff, (60 * 1000));
			});

			onUnmounted(() => {
				if(interval.value) clearInterval(interval.value);
			});

			const select = () => emit('select', props.order);

			const dragstart = (e) => {
				e.dataTransfer.setData('id', props.order.id);
				e.dataTransfer.setData('status', props.order.status);

				dragging.value = true;
			}

			const dragend = (e) => {
				e.preventDefault();
				dragging.value = false;
			}

			watch(dragging, (newValue) => setTimeout(() => emit('setdrag', newValue), 100));

			watch(() => props.order, (newValue) => {
				if(newValue) {
					reload(newValue);
					updateDiff();
				}
			}, { deep: true });

			return { title, paymentsInfo, typeInfo, typeTitle, shippingInfo, hasShipping, shipping, timerClass, timerText, draggable, dragging, select, dragstart, dragend };
		}
	}
</script>

<style scoped>
	.card {
		cursor: move;
	}

	.border-start {
		border-top-width: 1px !important;
		border-right-width: 1px !important;
		border-bottom-width: 1px !important;
	}
</style>