<template>
	<div class="row mx-0 gy-2 my-2" v-if="form.store_id">
		<div class="col-12 px-5 mt-1">
			<div class="form-check-reverse form-switch d-flex justify-content-between align-items-center position-relative">
				<label class="text-sm text-dark fw-bold stretched-link" for="formTypeInmediateOpen">Instantáneos</label>
				<div class="text-end d-flex align-items-center">
					<small class="me-2" :class="{ 'text-primary': form.type.inmediate.open, 'text-muted': !form.type.inmediate.open }">{{ (form.type.inmediate.open) ? 'Activo' : 'Inactivo' }}</small>
					<input type="checkbox" id="formTypeInmediateOpen" v-model="form.type.inmediate.open" class="form-check-input" :disabled="submitting">
				</div>
			</div>
		</div>
		<div class="col-12 px-5 mt-1" v-if="form.type.inmediate.open && 'LOCAL' in form?.type?.inmediate?.services">
			<div class="form-check-reverse form-switch d-flex justify-content-between align-items-center position-relative">
				<label class="text-sm stretched-link" for="formTypeInmediateServicesLocal">En local</label>
				<div class="text-end d-flex align-items-center">
					<small class="me-2" :class="{ 'text-primary': form.type.inmediate.services['LOCAL'], 'text-muted': !form.type.inmediate.services['LOCAL'] }">{{ (form.type.inmediate.services['LOCAL']) ? 'Activo' : 'Inactivo' }}</small>
					<input type="checkbox" id="formTypeInmediateServicesLocal" v-model="form.type.inmediate.services['LOCAL']" class="form-check-input" :disabled="submitting">
				</div>
			</div>
		</div>
		<div class="col-12 px-5 mt-1" v-if="form.type.inmediate.open && 'TAKEAWAY' in form?.type?.inmediate?.services">
			<div class="form-check-reverse form-switch d-flex justify-content-between align-items-center position-relative">
				<label class="text-sm stretched-link" for="formTypeInmediateServicesTakeaway">Take away</label>
				<div class="text-end d-flex align-items-center">
					<small class="me-2" :class="{ 'text-primary': form.type.inmediate.services['TAKEAWAY'], 'text-muted': !form.type.inmediate.services['TAKEAWAY'] }">{{ (form.type.inmediate.services['TAKEAWAY']) ? 'Activo' : 'Inactivo' }}</small>
					<input type="checkbox" id="formTypeInmediateServicesTakeaway" v-model="form.type.inmediate.services['TAKEAWAY']" class="form-check-input" :disabled="submitting">
				</div>
			</div>
		</div>
		<div class="col-12 px-5 mt-1" v-if="form.type.inmediate.open && 'DELIVERY' in form?.type?.inmediate?.services">
			<div class="form-check-reverse form-switch d-flex justify-content-between align-items-center position-relative">
				<label class="text-sm stretched-link" for="formTypeInmediateServicesDelivery">Delivery</label>
				<div class="text-end d-flex align-items-center">
					<small class="me-2" :class="{ 'text-primary': form.type.inmediate.services['DELIVERY'], 'text-muted': !form.type.inmediate.services['DELIVERY'] }">{{ (form.type.inmediate.services['DELIVERY']) ? 'Activo' : 'Inactivo' }}</small>
					<input type="checkbox" id="formTypeInmediateServicesDelivery" v-model="form.type.inmediate.services['DELIVERY']" class="form-check-input" :disabled="submitting">
				</div>
			</div>
		</div>
		<div class="col-12 px-5 mt-1">
			<div class="form-check-reverse form-switch d-flex justify-content-between align-items-center position-relative">
				<label class="text-sm text-dark fw-bold stretched-link" for="formPreorder">Programados</label>
				<div class="text-end d-flex align-items-center">
					<small class="me-2" :class="{ 'text-primary': form.preorder, 'text-muted': !form.preorder }">{{ (form.preorder) ? 'Activo' : 'Inactivo' }}</small>
					<input type="checkbox" id="formPreorder" v-model="form.preorder" class="form-check-input" :disabled="submitting">
				</div>
			</div>
		</div>
		<div class="col-12 px-5 mt-3">
			<div class="input-group input-group-sm">
				<button type="button" class="btn btn-outline-secondary" :disabled="submitting" @click="delaySub"><i class="bi bi-dash"></i></button>
				<input type="text" v-model="form.delay" class="form-control border-secondary" readonly>
				<span class="input-group-text border-secondary" id="inputGroup-sizing-sm">Demora</span>
				<button type="button" class="btn btn-outline-secondary" :disabled="submitting" @click="delayAdd"><i class="bi bi-plus"></i></button>
			</div>
		</div>
		<div class="col-12 px-5 mt-3" v-if="shippingGateway && (shippingGateway.config?.delay_display ?? false)">
			<div class="alert bg-dark d-flex justify-content-between text-white rounded px-2 py-1 text-sm" role="alert">
				<div>Demora de <b>{{ shippingGateway.name }}</b>:</div>
				<b>{{ shippingGateway.config.delay }} min</b>
			</div>
		</div>
		<div class="col-12 px-5 mt-3" v-if="form.type.inmediate.open && form.autoclose">
			<div class="alert bg-warning d-flex justify-content-between text-white rounded px-2 py-1 text-sm" role="alert">
				<div>Cierre <b>automático:</b></div>
				<b>{{ autocloseFormatted }}</b>
			</div>
		</div>
	</div>
</template>

<script>
	import { ref, reactive, computed, watch, onMounted, onUnmounted } from 'vue';
	import moment from 'moment';
	import _ from 'lodash';
	import StoreRepository from '@/repositories/StoreRepository';
	import composableStore from '@/composables/store';
	import composableForm from '@/composables/form';

	export default {
		setup() {
			const { current_store, status, gateways } = composableStore();
			const { submitting, catchError } = composableForm();

			const form = reactive({});
			const timeout = ref(null);

			const shippingGateway = computed(() => _.find(gateways.value, { type: 'shipping' }));
			const autocloseFormatted = computed(() => moment(form.autoclose ?? null).format('HH:mm[Hs]'));

			const submit = async (newValue) => {
				if(!_.isEqual(status.value, newValue)) {
					submitting.value = true;
					await StoreRepository.updateStatus(current_store.value.id, newValue).catch(catchError);
					submitting.value = false;
				}
			}

			const delaySub = () => {
				var delay = form.delay - 5;

				if(delay < 0) form.delay = 0;
				else form.delay = delay;
			}

			const delayAdd = () => {
				form.delay+= 5;
			}

			watch(status, (newValue) => {
				Object.assign(form, _.cloneDeep(newValue));
			});

			watch(form, (newValue) => {
				if(timeout.value) clearTimeout(timeout.value);
				timeout.value = setTimeout(() => submit(newValue), 1000);
			});

			onMounted(() => {
				Object.assign(form, _.cloneDeep(status.value));
			});

			onUnmounted(() => {
				if(timeout.value) clearTimeout(timeout.value);
			});

			return { form, submitting, shippingGateway, autocloseFormatted, delaySub, delayAdd };
		}
	}
</script>

<style scoped>
	.form-switch {
		min-height: 19px !important;
	}

	.form-switch .form-check-input {
		width: 2.5em;
		height: 1.25rem;
		margin-top: 0;
	}
</style>