<template>
	<div class="progress-wrapper mt-4">
		<div class="d-flex justify-content-between mb-1">
			<span class="progress-label h6 text-sm text-muted">Seguimiento</span>
			<span class="progress-percentage h6 text-sm" :class="`text-${shippingInfo.class}`">{{ shippingInfo.title }}</span>
		</div>
		<div class="progress mb-0">
			<div class="progress-bar" :class="`bg-${shippingInfo.class}`" role="progressbar" aria-valuemin="0" aria-valuemax="100" :style="{ width: `${shippingInfo.progress}%` }"></div>
		</div>
		<div class="mt-2 d-flex flex-wrap gap-2 justify-content-between" v-if="rider || status == 'ACCEPTED'">
			<p class="text-sm lh-xxs text-success" v-if="rider"><i class="bi bi-person-square me-1"></i>Rider: <b>{{ rider }}</b></p>
			<p class="text-sm lh-xxs text-warning" v-if="shippingGateway && (shippingGateway.config?.order_ready ?? false) && status == 'ACCEPTED'">Pasar a "<b>Por entregar</b>" cuando esté listo.</p>
		</div>
	</div>
</template>

<script>
	import { computed } from 'vue';
	import _ from 'lodash';
	import composableStore from '@/composables/store';

	export default {
		props: {
			status: String,
			shippingInfo: Object,
			rider: String
		},
		setup() {
			const { gateways } = composableStore();
			const shippingGateway = computed(() => _.find(gateways.value, { type: 'shipping' }));

			return { shippingGateway };
		}
	}
</script>